@charset "UTF-8";
/* CSS Document */
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  word-break: normal;
  word-wrap: break-word;
}

body {
  font-family: ヒラギノ明朝 Pro W3,Hiragino Mincho Pro,HiraMinProN-W3,游明朝, Yu Mincho,游明朝体,YuMincho,HGS明朝E,HG明朝E,ＭＳ Ｐ明朝,MS PMincho, "ヒラギノ明朝 Pro W3","Hiragino Mincho Pro","HiraMinProN-W3","游明朝", "Yu Mincho","游明朝体","YuMincho","HGS明朝E","HG明朝E",     "Hiragino Mincho Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  letter-spacing: 0.05em;
  background-color: #fff;
  color: #000;
  min-width: 1200px;
}

img {
  vertical-align: bottom;
  margin: 0;
  padding: 0;
  border: 0;
  max-width: 100%;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: " ";
}

.clearfix:after {
  clear: both;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

td,
th {
  padding: 0;
}

table {
  background-color: transparent;
}

table col[class*="col-"] {
  position: static;
  display: table-column;
  float: none;
}

table td[class*="col-"],
table th[class*="col-"] {
  position: static;
  display: table-cell;
  float: none;
}

ul li {
  list-style: none;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0px;
}

h1 {
  font-size: 300%;
}

h2 {
  font-size: 250%;
}

h3 {
  font-size: 200%;
}

h4 {
  font-size: 150%;
}

h5 {
  font-size: 125%;
}

.fixedcontainer {
  width: 1000px;
  margin: 0 auto;
  padding: 0px;
}

a:visited,
a:link,
a:hover,
a:active,
a:focus {
  outline: none;
  text-decoration: none;
}

a:hover {
  opacity: 0.8;
  transition: opacity 0.5s ease;
}

.red {
  color: #ed1c24;
}

.bkyellow {
  background-color: #fff100;
}

.yline {
  background: linear-gradient(transparent 60%, #fff100 0%);
}

.bold {
  font-weight: bold;
}

.btline {
  text-decoration: underline;
}

.underline {
  text-decoration: underline;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.visible-sp {
  display: none;
}

.hidden-sp {
  display: block;
}

.visible-xs {
  display: none;
}

.hidden-xs {
  display: block;
}

.visible-500 {
  display: none;
}

.hidden-500 {
  display: block;
}

.flex_part .flex_img_left {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.flex_part .flex_img_right {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.inline_flex_part {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

.fixedcontainer {
  width: 1000px;
  margin: auto;
}

/*-------------header----------------------*/
header .main {
  background: url(../images/fv_bg.jpg) no-repeat;
  background-size: 2000px 100%;
  background-position: center;
}

#sec1 h2 {
  height: 480px;
  background: url(../images/sec1_ttl.jpg) no-repeat;
  background-position: center;
  background-size: 2000px 100%;
}

#sec1 .bg1 {
  background: url(../images/sec1_bg.jpg) no-repeat;
  background-size: 2000px 100%;
  background-position: center;
}

#sec1 .part1 {
  width: 1200px;
  margin: auto;
  padding-bottom: 100px;
  padding-top: 20px;
}

#sec1 .part1 > div {
  padding-top: 60px;
}

#sec1 .part1 .flex_header {
  width: 480px;
}

#sec1 .part1 .flex_body {
  width: 660px;
}

#sec1 .part1 .flex_body h5 {
  font-size: 28px;
  border-bottom: 2px solid #00a0e9;
  margin-bottom: 30px;
  font-weight: lighter;
  display: inline-block;
  letter-spacing: -1px;
  padding-top: 10px;
}

#sec1 .part1 .flex_body p {
  font-size: 24px;
}

#sec2 h2 {
  text-align: center;
}

#sec2 .part2 {
  margin: auto;
}

#sec2 .part2 > div {
  padding: 50px 0;
  justify-content: center;
}

#sec2 .part3 {
  margin-top: 70px;
  padding-bottom: 60px;
  background: url(../images/sec2_bg.jpg) no-repeat;
  background-size: 2000px 100%;
}

#sec2 .part3 .container {
  background: rgba(247, 248, 248, 0.9);
  padding: 80px 30px 45px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.75);
}

#sec2 .part3 .inline_flex_part .item {
  width: 300px;
  padding: 0 20px 30px;
  border: 1px solid #00a0e9;
}

#sec2 .part3 .inline_flex_part .item .item_header {
  text-align: center;
  margin-top: -40px;
  font-size: 35px;
  line-height: 62px;
}

#sec2 .part3 .inline_flex_part .item .item_header span {
  font-size: 52px;
  color: #e60012;
}

#sec2 .part3 .inline_flex_part .item .item_part1 {
  padding-top: 5px;
}

#sec2 .part3 .inline_flex_part .item .item_part2 {
  height: 90px;
  margin: 20px 0;
}

#sec2 .part3 .inline_flex_part .item .item_part2 p {
  font-size: 28px;
  text-align: center;
  letter-spacing: 0;
}

#sec2 .part3 .inline_flex_part .item .item_part2 p span {
  line-height: 45px;
}

#sec2 .part3 .inline_flex_part .item .item_part2 p span.item1_header {
  line-height: 90px;
}

#sec2 .part3 .inline_flex_part .item .item_part3 {
  font-size: 18px;
}

#sec2 .part3 .inline_flex_part .item .item_part3 a {
  color: #3852c0;
}

#sec3 h2 {
  text-align: center;
}

#sec3 .part1 h3 {
  position: relative;
}

#sec3 .part1 h3 span {
  text-align: left;
  color: #e60012;
  font-size: 24px;
  padding: 50px 40px;
  display: inline-block;
  background-color: white;
}

#sec3 .part1 h3::before {
  position: absolute;
  left: 0;
  top: 50%;
  content: "";
  width: 100%;
  display: inline-block;
  height: 1px;
  background-color: #00a0e9;
  z-index: -1;
}

#sec3 .part2 {
  padding: 50px 0 80px;
}

#sec4 {
  background: url(../images/sec4_bg.jpg) no-repeat;
  background-size: 2000px auto;
  background-position: center;
}

#sec4 h2 {
  text-align: center;
  padding: 60px;
}

#sec4 .part1 {
  text-align: center;
  padding-bottom: 90px;
}

#sec4 .part1 p {
  font-size: 28px;
  letter-spacing: 0;
}

#sec5 .part1 .toiawase_form {
  width: 800px;
  margin: 68px auto 60px;
  background: url(./../images/sec5_bg.png) no-repeat;
  text-align: right;
  padding-right: 50px;
  position: relative;
}

#sec5 .part1 .toiawase_form::after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 215px;
  left: 0;
  bottom: 0;
  height: 100%;
  background: url(../images/sec5_3.png) no-repeat;
  background-position: left 10px bottom;
}

#sec5 .part1 .toiawase_form a {
  display: block;
}

#sec5 .part1 .toiawase_form a.btn1 {
  padding-top: 90px;
}

#sec5 .part1 .toiawase_form a.btn2 {
  margin-top: 30px;
  padding-bottom: 40px;
}

#sec5 .part1 .toiawase_form2 {
  margin: auto;
  margin-top: 80px;
  margin-bottom: 60px;
}

#sec5 .part2 {
  margin-bottom: 100px;
}

#sec5 .part2 table {
  width: 630px;
  margin: auto;
}

#sec5 .part2 table th {
  text-align: left;
  font-size: 20px;
  width: 200px;
  line-height: 2;
}

#sec5 .part2 table td {
  text-align: left;
  font-size: 20px;
  color: #00a0e9;
}

/*-----------        -----------------*/
/* CSS Document */
@media screen and (min-width: 768px) {
  .sp {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .pc {
    display: none;
  }
  body {
    min-width: 100%;
    font-size: 3.8vw;
  }
  .flex_part .flex_img_left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .flex_part .flex_img_right {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .inline_flex_part {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
  }
  .fixedcontainer {
    width: 100%;
    margin: auto;
  }
  /*-------------header----------------------*/
  header .main {
    background: none;
  }
  #sec1 h2 {
    height: initial;
    background: none;
  }
  #sec1 .bg1 {
    background: none;
  }
  #sec1 .part1 {
    width: 92vw;
    margin: auto;
    padding-bottom: 5vw;
    padding-top: 2.5vw;
  }
  #sec1 .part1 > div {
    padding-top: 7.5vw;
  }
  #sec1 .part1 .flex_header {
    width: 100%;
  }
  #sec1 .part1 .flex_body {
    width: 100%;
  }
  #sec1 .part1 .flex_body h5 {
    font-size: 5vw;
    border-bottom: 2px solid #00a0e9;
    margin-bottom: 3vw;
    margin-top: 5vw;
    font-weight: lighter;
    display: inline-block;
    letter-spacing: -1px;
    padding-top: 0;
  }
  #sec1 .part1 .flex_body p {
    font-size: 4vw;
  }
  #sec2 h2 {
    text-align: center;
  }
  #sec2 .part2 {
    margin: auto;
  }
  #sec2 .part2 > div {
    padding: 3vw 0;
    justify-content: center;
  }
  #sec2 .part3 {
    margin-top: 6vw;
    padding-bottom: 4vw;
    background: unset;
  }
  #sec2 .part3 .container {
    background: rgba(247, 248, 248, 0.9);
    padding: 3vw 3vw 4vw;
    width: 92vw;
    margin: auto;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.75);
  }
  #sec2 .part3 .inline_flex_part .item {
    width: 100%;
    padding: 2vw 2vw 6vw;
    border: 1px solid #00a0e9;
    margin-top: 4vw;
  }
  #sec2 .part3 .inline_flex_part .item .item_header {
    text-align: center;
    margin-top: 0;
    width: 45%;
    margin: auto;
    line-height: unset;
    font-size: unset;
  }
  #sec2 .part3 .inline_flex_part .item .item_header span {
    font-size: 6vw;
    color: #e60012;
  }
  #sec2 .part3 .inline_flex_part .item .item_part1 {
    padding-top: 1vw;
  }
  #sec2 .part3 .inline_flex_part .item .item_part2 {
    height: unset;
    margin: 3vw 0;
  }
  #sec2 .part3 .inline_flex_part .item .item_part2 p {
    font-size: 5.2vw;
    text-align: center;
    letter-spacing: 0;
  }
  #sec2 .part3 .inline_flex_part .item .item_part2 p span {
    line-height: 1.5;
  }
  #sec2 .part3 .inline_flex_part .item .item_part2 p span.item1_header {
    line-height: 1.5;
  }
  #sec2 .part3 .inline_flex_part .item .item_part3 {
    font-size: 4.5vw;
  }
  #sec2 .part3 .inline_flex_part .item .item_part3 a {
    color: #3852c0;
  }
  #sec3 h2 {
    text-align: center;
  }
  #sec3 .part1 h3 {
    position: relative;
  }
  #sec3 .part1 h3 span {
    text-align: left;
    color: #e60012;
    font-size: 6vw;
    padding: 5vw 4vw;
    display: inline-block;
    background-color: white;
  }
  #sec3 .part1 h3::before {
    position: absolute;
    left: 0;
    top: 50%;
    content: "";
    width: 100%;
    display: inline-block;
    height: 1px;
    background-color: #00a0e9;
    z-index: -1;
  }
  #sec3 .part2 {
    padding: 5vw 0 8vw;
  }
  #sec4 {
    background: none;
  }
  #sec4 h2 {
    text-align: center;
    padding: 0;
  }
  #sec4 .part1 {
    text-align: center;
    padding-bottom: 6vw;
  }
  #sec4 .part1 p {
    font-size: 4.5vw;
    letter-spacing: 0;
  }
  #sec5 .part1 .toiawase_form2 {
    width: 90vw;
    margin: auto;
    margin-top: 6vw;
    margin-bottom: 6vw;
  }
  #sec5 .part1 .toiawase_form {
    width: 100%;
    margin: 5vw auto 5vw;
    background: url(./../images/sec5_bg_sp.png) no-repeat;
    text-align: right;
    padding-right: 0;
    position: relative;
    background-size: 100% auto;
  }
  #sec5 .part1 .toiawase_form::after {
    content: initial;
  }
  #sec5 .part1 .toiawase_form a {
    width: 85vw;
    margin: auto;
    display: block;
  }
  #sec5 .part1 .toiawase_form a.btn1 {
    padding-top: 14.4vw;
  }
  #sec5 .part1 .toiawase_form a.btn2 {
    margin-top: 2vw;
    padding-bottom: 4vw;
  }
  #sec5 .part2 {
    margin-bottom: 8vw;
    padding: 0 4vw;
  }
}

@media screen and (max-width: 500px) {
  .visible-500 {
    display: block;
  }
  .hidden-500 {
    display: none;
  }
}
